import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"
import AdSense from "react-adsense"

import "./layout.css"

import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"

config.autoAddCss = false

export default ({ children }) => (
  <div className="flex flex-col min-h-screen">
    <Header />
    {children}
    <AdSense.Google
      client="ca-pub-2223665761819643"
      slot="6435117441"
      style={{ display: "block" }}
      format="auto"
      responsive="true"
    />
    <Footer />
  </div>
)
