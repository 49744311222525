import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      essays: allAirtableCategories(
        filter: { data: { parentCategory: { eq: "essays" } } }
        sort: { fields: data___displayNumber }
      ) {
        edges {
          node {
            data {
              nameJa
              path
              slug
              parentCategory
              displayNumber
            }
          }
        }
      }
      keywords: allAirtableCategories(
        filter: { data: { parentCategory: { eq: "keywords" } } }
        sort: { fields: data___displayNumber }
      ) {
        edges {
          node {
            data {
              nameJa
              path
              slug
              parentCategory
              displayNumber
            }
          }
        }
      }
    }
  `)

  return (
    <footer className="footer p-12 bg-red-800">
      <div className="flex flex-col sm:flex-row sm:justify-around">
        <div className="mt-5">
          <h3 className="text-xl text-secondary font-bold">エッセイ集</h3>
          <ul>
            {data.essays.edges.map(({ node: { data } }) => (
              <li key={data.path}>
                <Link
                  to={`/${data.path}`}
                  className="block mt-2 text-red-200 hover:text-white mr-4"
                  alt={data.nameJa}
                >
                  {data.nameJa}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-5">
          <h3 className="text-xl text-secondary font-bold">キーワード集</h3>
          <ul>
            {data.keywords.edges.map(({ node: { data } }) => (
              <li key={data.path}>
                <Link
                  to={`/${data.path}`}
                  className="block mt-2 text-red-200 hover:text-white mr-4"
                  alt={data.nameJa}
                >
                  {data.nameJa}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-5">
          <h3 className="text-xl text-secondary font-bold">
            このサイトについて
          </h3>
          <ul>
            <li key={`privacy`}>
              <Link
                to={`/privacy/`}
                className="block mt-2 text-red-200 hover:text-white mr-4"
                alt="プライバシーポリシー"
              >
                プライバシーポリシー
              </Link>
            </li>
            <li key={`copyright`}>
              <Link
                to={`/copyright/`}
                className="block mt-2 text-red-200 hover:text-white mr-4"
                alt="著作権について"
              >
                著作権について
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex content-end justify-around mt-20">
        © 2020 英語対訳で読む日本の文化.
      </div>
    </footer>
  )
}
