import React, { useState } from "react"
import { Link } from "gatsby"

export default () => {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <header className="header">
      <nav className="flex items-center justify-between flex-wrap bg-red-800 p-6">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <span className="font-semibold text-xl tracking-tight">
            <Link to={`/`}>英語対訳で読む日本の文化</Link>
          </span>
        </div>
        <div className="block lg:hidden">
          <button
            onClick={() => toggleExpansion(!isExpanded)}
            className="flex items-center px-3 py-2 border rounded text-red-200 hover:text-white hover:border-white">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            </svg>
          </button>
        </div>
        <div className={`${ isExpanded ? `block` : `hidden` } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}>
          <div className="text-sm lg:flex-grow">
            <Link
              to={"/essays/"}
              className="block mt-4 lg:inline-block lg:mt-0 text-red-200 hover:text-white mr-4"
              alt="エッセイ集"
            >
              エッセイ集
            </Link>
            <Link
              to={"/keywords/"}
              className="block mt-4 lg:inline-block lg:mt-0 text-red-200 hover:text-white mr-4"
              alt="キーワード集"
            >
              キーワード集
            </Link>
            <Link
              to={"/author/"}
              className="block mt-4 lg:inline-block lg:mt-0 text-red-200 hover:text-white"
              alt="著者について"
            >
              著者について
            </Link>
          </div>
        </div>
      </nav>
    </header>
  )
}
